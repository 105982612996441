<template>
  <!-- 平台活动新增编辑页面 -->
  <div>
    <Header
      back="返回"
      title="云学院"
      index="首页"
      titleOne="官网管理"
      titleTwo="云学院"
      titleThree="平台活动"
      beforeTitle="新增/编辑"
    />
    <div class="content">
      <div>
        <span>封面</span>
        <div class="flex_l" style="margin-top: 15px;">
          <Playcover ref="playcover" />
        </div>
      </div>
      <div>
        <el-form
          style="margin-top: 20px;"
          :rules="formRules"
          label-position="top"
          ref="form"
          :model="form"
        >
          <el-form-item label="活动名称" prop="title">
            <el-input v-model="playParams.title"></el-input>
          </el-form-item>
          <el-form-item label="活动时间">
            <div class="block">
              <el-date-picker
                style="width: 370px;"
                v-model="valueDate"
                type="daterange"
                value-format="yyyy-MM-dd"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!-- 富文本编辑框 -->
      <div class="about">
        <span class="policy_text" style="font-size: 16px;color: #000;">图文内容</span>
        <!-- <quill-editor
          v-loading="quillUpdateImg"
          style="margin-top: 15px;"
          v-model="playParams.content"
          ref="myQuillEditor"
          :options="editorOption"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          @change="onEditorChange($event)"
        ></quill-editor>-->

        <editor :content.sync="playParams.content"></editor>
      </div>

      <el-upload
        :data="objectPic"
        class="avatar-uploader1"
        :file-list="[]"
        :action="uploadUrl"
        :shrow-file-list="false"
        :on-success="uploadSuccess"
      />
      <div style="width: 100%;text-align: center;">
        <el-button type="primary" class="sure" :loading="btnLoading" @click="sure()">确定</el-button>
      </div>
    </div>
  </div>
</template>
  
<script>
import Gqburl from '../../components/global.vue'
import Playcover from '../../components/upload/uploadCover.vue'
import Editor from '../../components/tinymce.vue'
// import { quillEditor } from "vue-quill-editor";
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
// const toolbarOptions = [
//   ['bold', 'italic', 'underline', 'strike'], // toggled buttons
//   ['blockquote', 'code-block'],
//   [{ 'header': 1 }, { 'header': 2 }], // custom button values
//   [{ 'list': 'ordered' }, { 'list': 'bullet' }],
//   [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
//   [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
//   [{ 'align': [] }],
//   ['link', 'image', 'video'],
//   ['clean'] // remove formatting button
// ];
export default {
  components: {
    // quillEditor,
    Playcover,
    Editor,
  },
  data () {
    return {
      btnLoading: false,//提交按钮动画
      // quillUpdateImg: false, //富文本上传图片动画
      objectPic: {
        token: localStorage.getItem('token')
      },
      uploadUrl: '/api/website/upload_file',
      infoCover: '',//回显封面
      gqbpath: Gqburl.userSite,
      valueDate: [],//日期选择器
      playParams: {
        start_time: '',
        end_time: '',
      },

      // editorOption: {
      //   // placeholder: "请输入内容",
      //   theme: 'snow', // or 'bubble'
      //   modules: {
      //     toolbar: {
      //       container: toolbarOptions, // 工具栏
      //       handlers: {
      //         'image': function (value) {
      //           if (value) {
      //             // 触发input框选择图片文件
      //             document.querySelector('.avatar-uploader1 input').click()
      //           } else {
      //             this.quill.format('image', false);
      //           }
      //         }
      //       }
      //     }
      //   }
      // },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      form: {},
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      formRules: {
        // title: [
        //   { required: true, message: '内容不能为空', trigger: 'blur' },
        // ],
      },
    }
  },
  created () {
    if (this.$route.query.id) {
      this.playParams.id = this.$route.query.id
      this.videoView()
    }
  },
  methods: {
    // uploadSuccess (res, file) {
    //   this.quillUpdateImg = true
    //   console.log(res, 'res123')
    //   // res为图片服务器返回的数据
    //   // 获取富文本组件实例
    //   let quill = this.$refs.myQuillEditor.quill;
    //   // 如果上传成功
    //   if (res.url) {
    //     // 获取光标所在位置
    //     let length = quill.getSelection().index;
    //     // 插入图片  res.info为服务器返回的图片地址
    //     quill.insertEmbed(length, 'image', this.gqbpath + res.url)
    //     // 调整光标到最后
    //     quill.setSelection(length + 1)
    //     // this.$forceupdate()
    //   } else {
    //     this.$message.error('图片插入失败')
    //   }
    //   // loading动画消失
    //   this.quillUpdateImg = false;
    //   this.playParams.content + res.url
    // },
    videoView () {
      this.axios.get('/api/website/view_article', {
        params: {
          id: this.$route.query.id,
          type: 'activity'
        }
      }).then((res) => {
        this.playParams = res.data
        this.infoCover = res.data.cover_url
        this.valueDate.push(res.data.start_time, res.data.end_time)
      })
    },
    sure () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.$route.query.id) {
            this.playParams.cover_url = this.infoCover
          } else {
            this.playParams.cover_url = this.$refs.playcover.fileCover.url
          }
          this.playParams.start_time = this.valueDate[0]
          this.playParams.end_time = this.valueDate[1]
          this.btnLoading = true
          this.axios.post('/api/website/store_article', { ...this.playParams, type: 'activity' }).then((res) => {
            // console.log(res)
            this.btnLoading = false
            this.$message.success(res.message)
            this.$router.go(-1)
          })
            .catch((r) => {
              this.btnLoading = false
              this.$message.warning('请完善信息')
            })
        } else {
          this.$message.warning('请完善信息')
        }
      })
    },
    // onEditorReady (editor) {
    //   // 准备编辑器
    //   console.log("111");
    // },
    // onEditorBlur () {
    //   // 失去焦点事件
    //   console.log("失去焦点：");
    // },
    // onEditorFocus () {
    //   // 获得焦点事件
    //   console.log("222");
    // },
    // onEditorChange () {
    //   // 内容改变事件
    //   console.log("333");
    // },

  }
}
  </script>
  
  <style scoped>
.sure {
  margin: 50px auto;
}

.add_video {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.add_video i {
  display: block;
  font-size: 50px;
}

.add_video span {
  display: block;
  line-height: 16px;
  margin-top: 15px;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #0f38ff;
}

.add_video p {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  line-height: 14px;
  color: #909399;
}

.card_box {
  display: flex;
}

.flex_l {
  position: relative;
}

.flex_r {
  position: relative;
  margin-left: 20px;
}

div/deep/.el-upload--picture-card {
  width: 370px !important;
  height: 234px !important;
}

/* div/deep/.el-upload--picture-card{
      position: relative;
    } */

div/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 370px;
  height: 234px;
  position: absolute;
  top: 0;
  z-index: 9;
}

div/deep/.el-upload-list--picture-card .el-upload-list__item-actions {
  top: 0;
}
div/deep/.ql-container {
  min-height: 300px;
}
div/deep/.ql-editor img {
  max-width: 400px !important;
}
</style>